import { formatAmount } from '~/common/functions';
import { PAYMENT_AMOUNT_LIMIT } from '~/common/constants';
import type { VeeValidateFunction } from '~/interfaces/publisher';
import {
  type BaseCredentialsFields,
  CredentialsType,
  type LocalType,
  PaymentServiceRegistrationStatus,
} from '~/interfaces/publisher/payments';

export type FieldSettings = {
  inputName: string;
  inputType?: string;
  inputLabel?: string;
  validatingFunction?: VeeValidateFunction;
  className?: string;
  placeholder?: string;
};

export const REQUIRED_FIELD = 'Поле не должно быть пустым';

export const invalidInn = 'Длина ИНН должна быть не менее 10 символов';
export const invalidOgrn = 'Длина ОГРН должна быть не менее 13 символов';

export const validateRequiredField = <T = string>(toValidate: T): string | true => {
  if (!toValidate) {
    return REQUIRED_FIELD;
  }

  return true;
};

export const validatePaymentOrderAmount = (value = '') => {
  const unformatted = +value.split(' ').join('');
  if (!+unformatted || +unformatted < PAYMENT_AMOUNT_LIMIT.min) {
    return `Минимальная сумма для заказа выплаты - ${formatAmount(PAYMENT_AMOUNT_LIMIT.min)}`;
  }

  return true;
};

const emailRegExp = /[\w-.]+@/;
export const validateEmailField = (value: string | number) => {
  if (typeof value === 'number') {
    return 'Ошибка типов';
  }

  if (!emailRegExp.test(value)) {
    return 'Email введен некорректно';
  }

  return true;
};

const phoneRegExp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
export const validatePhoneField = (value: string | number) => {
  if (typeof value === 'number') {
    return 'Ошибка типов';
  }

  if (!phoneRegExp.test(value)) {
    return 'Телефон введен некорректно';
  }

  return true;
};

const getNotNumber = (toValidate: string) => {
  if (/\D/.test(toValidate)) return 'Значение поля должно состоять из цифр';
};

const validateDigitFieldWithMinimalLength = (
  toValidate: string | number,
  fieldLabel: string,
  minimalLength: number,
) => {
  if (typeof toValidate === 'number') {
    return 'Ошибка типов';
  }
  const notNumber = getNotNumber(toValidate);
  if (notNumber) {
    return notNumber;
  }
  if (toValidate.length < minimalLength) {
    return `Минимальная длина ${fieldLabel} - ${minimalLength} символов.`;
  }

  return true;
};

const getExactLengthDigitField = (toValidate: string, fieldName: string, exactLength: number) => {
  const notNumber = getNotNumber(toValidate);
  if (notNumber) {
    return notNumber;
  }
  if (toValidate.length !== exactLength) {
    return `Длина поля ${fieldName} - ${exactLength} символов.`;
  }

  return true;
};

export const validateLegalInnField = (value: string) => getExactLengthDigitField(value, 'ИНН', 10);

export const validatePersonInnField = (value: string) => getExactLengthDigitField(value, 'ИНН', 12);

export const validateBikField = (value: string | number) =>
  validateDigitFieldWithMinimalLength(value, 'БИК', 8);

export const validateOgrnipField = (value: string | number) =>
  validateDigitFieldWithMinimalLength(value, 'ОГРНИП', 15);

export const validateOgrnField = (value: string | number) =>
  validateDigitFieldWithMinimalLength(value, 'ОГРН', 13);

export const validateCheckingAccountField = (value: string | number) =>
  validateDigitFieldWithMinimalLength(value, 'рассчетного счета', 20);

export const validateTreasuryAccountField = (value: string | number) =>
  validateDigitFieldWithMinimalLength(value, 'казначейского счета', 20);

export const validateKppField = (value: string | number) =>
  validateDigitFieldWithMinimalLength(value, 'КПП', 9);

export const validateCompanyInn = (toValidate: string) => {
  const LEGAL_INN_LENGTH = 10;
  if (toValidate?.length !== LEGAL_INN_LENGTH) {
    return `Длина ИНН юридического лица - ${LEGAL_INN_LENGTH} символов`;
  }
  return true;
};

export const validatePersonInn = (toValidate: string) => {
  const PHYSICAL_INN_LENGTH = 12;
  if (toValidate?.length !== PHYSICAL_INN_LENGTH) {
    return `Длина ИНН физического лица - ${PHYSICAL_INN_LENGTH} символов`;
  }
  return true;
};

export const emailField = {
  inputName: 'email',
  inputType: 'text',
  inputLabel: 'Email',
  validatingFunction: validateEmailField,
  className: 'order-2',
};

export const baseCredentialsFieldsTemplate: Record<keyof BaseCredentialsFields, FieldSettings> = {
  fullName: {
    inputName: 'fullName',
    inputType: 'text',
    inputLabel: 'ФИО',
    validatingFunction: validateRequiredField,
    className: 'order-1',
  },
  email: emailField,
};

export const phoneField = {
  inputName: 'phone',
  inputType: 'text',
  inputLabel: 'Телефон',
  validatingFunction: validatePhoneField,
  className: 'order-3',
};
export const baseCredentialsFieldsTemplateWithPhone = {
  ...baseCredentialsFieldsTemplate,
  phone: phoneField,
} as const;

export const getPaymentServiceStatusTemplate = (inputLabel: string): FieldSettings => ({
  inputName: 'status',
  inputType: 'select',
  inputLabel,
  validatingFunction: validateRequiredField,
  className: '',
});

const getInnField = (validator: VeeValidateFunction) => ({
  inputName: 'inn',
  inputType: 'text',
  inputLabel: 'ИНН',
  validatingFunction: validator,
  className: 'order-2',
});

const personAndCompanyCommonFieldsTemplate = {
  ...baseCredentialsFieldsTemplateWithPhone,
  bankName: {
    inputName: 'bankName',
    inputType: 'text',
    inputLabel: 'Наименование банка',
    validatingFunction: validateRequiredField,
    className: 'order-5',
  },
  bik: {
    inputName: 'bik',
    inputType: 'text',
    inputLabel: 'БИК',
    validatingFunction: validateBikField,
    className: 'order-5',
  },
  address: {
    inputName: 'address',
    inputType: 'text',
    inputLabel: 'Адрес для доставки документов',
    validatingFunction: validateRequiredField,
    className: 'order-7',
  },
  checkingAccount: {
    inputName: 'checkingAccount',
    inputType: 'text',
    inputLabel: 'Р/C',
    validatingFunction: validateCheckingAccountField,
    className: 'order-4',
  },
  treasuryAccount: {
    inputName: 'treasuryAccount',
    inputType: 'text',
    inputLabel: 'К/C',
    validatingFunction: validateTreasuryAccountField,
    className: 'order-6',
  },
};

const companyCredentialsTemplate = {
  ...personAndCompanyCommonFieldsTemplate,
  inn: getInnField(validateLegalInnField),
  organisationName: {
    inputName: 'organisationName',
    inputType: 'text',
    inputLabel: 'Наименование организации',
    validatingFunction: validateRequiredField,
    className: 'order-4',
  },
  basedOnDocument: {
    inputName: 'basedOnDocument',
    inputType: 'text',
    inputLabel: 'На основании чего действует',
    validatingFunction: validateRequiredField,
    className: 'order-5',
  },
  ogrn: {
    inputName: 'ogrn',
    inputType: 'text',
    inputLabel: 'ОГРН',
    validatingFunction: validateOgrnField,
    className: 'order-6',
  },
  kpp: {
    inputName: 'kpp',
    inputType: 'text',
    inputLabel: 'КПП',
    validatingFunction: validateKppField,
    className: 'order-7',
  },
};

const personCredentialsTemplate = {
  ...personAndCompanyCommonFieldsTemplate,
  inn: getInnField(validatePersonInn),
  ogrnip: {
    inputName: 'ogrnip',
    inputType: 'text',
    inputLabel: 'ОГРНИП',
    validatingFunction: validateOgrnipField,
    className: 'order-3',
  },
};

export const mappedCredentialsTemplate: Record<LocalType, { [key: string]: FieldSettings }> = {
  [CredentialsType.SOLE_PROPRIETOR]: personCredentialsTemplate,
  [CredentialsType.SELF_EMPLOYED]: personCredentialsTemplate,
  [CredentialsType.COMPANY]: companyCredentialsTemplate,
};

export const konsolAccountStatusOptions = [
  {
    value: PaymentServiceRegistrationStatus.registered,
    name: 'Зарегистрирован',
  },
  {
    value: PaymentServiceRegistrationStatus.registration_requested,
    name: 'Нет. Зарегистрируйте меня',
  },
  {
    value: PaymentServiceRegistrationStatus.not_required,
    name: 'Не требуется',
  },
];

export const konsolInfo = {
  title: 'Как получать деньги за рекламу в своих телеграм-ботах при помощи сервиса Консоль',
  description: `
  Нужно подключиться к сервису «Консоль» и получить статус самозанятого. Это не сложно и занимает 10 минут – всего три шага, которые надо проделать один раз:
1. Мы направляем вам SMS с приглашением в сервис «Консоль» (от вас нужен номер телефона и полное ФИО).
2. Вы регистрируетесь как самозанятый/ая, ⏰ 5 минут
3. Сервис проверит ваши данные ⏰ 5-10 минут и бесплатно выпустит электронную цифровую подпись. Это избавит от обмена бумажными документами.
Можем работать!

Получение оплаты еще проще:
1. Мы сами создаем задание с описанием услуги, сроков и суммы; и выпускаем акт выполненных работ.
2. Вы подписываете акт (1 клик).
3. Мы направляем вам выплату, которая прилетает сразу.

Тут 👉🏻 <a href="https://docs.google.com/document/d/1xkahoInzbW_YGZqeZydA2M3owRkdQhp3OPWgw-WUXos/edit#heading=h.xb3v8t9tetsl" target="_blank">пошаговая инструкция</a> по работе через «Консоль»
А здесь 👉🏻 <a href="https://docs.google.com/document/d/1nQTwRjoaOMYW-1W_Rr1-vhg7o6ICSbo-gXL3k893LSU/edit">шпаргалка</a> для тех, кто работает с нами как ИП или ООО
Остались вопросы? Пишите в техподдержку на нашем сайте.
  `,
};
