export const enum PaymentTab {
  'PAYMENT' = 'PAYMENT',
  'HISTORY' = 'HISTORY',
}

export const enum PaymentTabTab {
  'paymentOrder' = 'paymentOrder',
  'createCredentials' = 'createCredentials',
  'editCredentials' = 'editCredentials',
}

export enum TransactionStatus {
  'INITIATE' = 1,
  'WAIT' = 2,
  'COMPLETE' = 3,
  'CANCELED' = 100,
  'DECLINED' = 101,
  'ERROR' = 200,
}

export const enum TransactionType {
  'INCOMING' = 1,
  'OUTGOING' = 2,
}

export const enum CredentialsType {
  'SOLE_PROPRIETOR' = 1,
  'COMPANY' = 2,
  'SELF_EMPLOYED' = 3,
  'INDIVIDUAL' = 4,
  'INTERNATIONAL' = 'international',
}

type TransactionCommon = {
  id: number;
  createdAt: string;
  status: TransactionStatus;
  description: string;
  type: TransactionType;
};

export interface Transaction extends TransactionCommon {
  amount: number;
}

export const enum PaymentServiceRegistrationStatus {
  'registered' = 'registered',
  'registration_not_found' = 'registration_not_found',
  'registration_requested' = 'registration_requested',
  'registration_failed' = 'registration_failed',
  'not_required' = 'not_required',
}

export enum PaymentServiceAlias {
  'KONSOL' = 'konsol',
  'MADE_TASK' = 'madeTask',
  'SOLAR_STAFF' = 'solarStaff',
}

export type InternationalCredentialsData = object;

export type PaymentServiceData = {
  registrationStatus: PaymentServiceRegistrationStatus;
};

export type BaseCredentialsFields = {
  fullName: string;
  email: string;
};

export type BaseCredentialsFieldsAndPhone = BaseCredentialsFields & {
  phone?: number | null;
};

export type PersonAndCompanyCommonFields = BaseCredentialsFields & {
  inn: string;
  bankName: string;
  bik: string;
  address: string;
  checkingAccount: string;
  treasuryAccount: string;
  phone: string;
};

type PaymentServiceDataWithPhone = PaymentServiceData & {
  phone: string;
};

export type KonsolData =
  | {
      registrationStatus: PaymentServiceRegistrationStatus.not_required;
      phone: null;
    }
  | PaymentServiceDataWithPhone;

export type PersonCredentialsData = PersonAndCompanyCommonFields &
  BaseCredentialsFieldsAndPhone & {
    ogrnip: string;
  } & {
    service: {
      [PaymentServiceAlias.KONSOL]: KonsolData;
    };
  };

export type CompanyCredentialsData = PersonAndCompanyCommonFields & {
  organisationName: string;
  basedOnDocument: string;
  ogrn: string;
  kpp: string;
};

export type ConsoleData = {
  consoleData: {
    registrationStatus: PaymentServiceRegistrationStatus;
    consoleInvitation?: object;
    consoleContractor?: object;
  };
};

type CredentialsCommon = {
  id?: number;
  type: CredentialsType;
  countryId: number;
};

type PersonCredentialsBody<T extends CredentialsType> = CredentialsCommon & {
  type: T;
  data: PersonCredentialsData;
};

export type SoleProprietorCredentialsBody = PersonCredentialsBody<CredentialsType.SOLE_PROPRIETOR>;

export type SelfEmployedCredentialsBody = PersonCredentialsBody<CredentialsType.SELF_EMPLOYED>;

export type CompanyCredentialsBody = CredentialsCommon & {
  type: CredentialsType.COMPANY;
  data: CompanyCredentialsData;
};

export type SolarStaffServiceData = PaymentServiceDataWithPhone & BaseCredentialsFields;

type SolarStaffService = {
  [PaymentServiceAlias.SOLAR_STAFF]: SolarStaffServiceData;
};

export type MadeTaskServiceData = PaymentServiceData & BaseCredentialsFields;

export type MadeTaskService = {
  [PaymentServiceAlias.MADE_TASK]: MadeTaskServiceData;
};

export type AtLeastOneOfIndividualPaymentServices = {
  service: (SolarStaffService & MadeTaskService) | SolarStaffService | MadeTaskService;
};

export type InitIndividualCredentialsData = AtLeastOneOfIndividualPaymentServices & {
  service: SolarStaffService | MadeTaskService | (SolarStaffService & MadeTaskService) | object;
};

export type IndividualCredentialsData = AtLeastOneOfIndividualPaymentServices;

export type IndividualCredentialsBody = CredentialsCommon & {
  type: CredentialsType.INDIVIDUAL;
  data: IndividualCredentialsData;
};

export type CredentialsData<T> = T extends CredentialsType.COMPANY
  ? CompanyCredentialsData
  : T extends CredentialsType.INDIVIDUAL
  ? IndividualCredentialsData
  : T extends CredentialsType.INTERNATIONAL
  ? object
  : PersonCredentialsData;

export type CredentialsInitBody<T = any> = {
  type: CredentialsType | undefined;
  countryId: number;
  data: CredentialsData<T> | object;
};

export type CredentialsBody =
  | SoleProprietorCredentialsBody
  | CompanyCredentialsBody
  | IndividualCredentialsBody
  | SelfEmployedCredentialsBody;

export type Credentials = CredentialsBody & {
  id: number;
};

export const enum PaymentVia {
  'ACCOUNT' = 1,
  'KONSOL' = 2,
}

type PaymentCommon = {
  paymentVia: PaymentVia;
};

export interface Payment extends Transaction, PaymentCommon {
  credentials: Credentials;
  amount: number;
}

export interface PaymentView extends TransactionCommon, PaymentCommon {
  amount: string;
  credentials: string;
}

export interface PaymentRequest {
  paymentVia: PaymentVia;
  amount: number;
  credentialsId: number;
}

export type ServiceCredentials = IndividualCredentialsData | PersonCredentialsData;

export type LocalType =
  | CredentialsType.SOLE_PROPRIETOR
  | CredentialsType.SELF_EMPLOYED
  | CredentialsType.COMPANY;

export type CredentialsForm<T = undefined> = {
  countryId: number;
  type: CredentialsType | undefined;
  data: T extends CredentialsData<T> ? T : { [key: string]: any };
};

export type CredentialsTypedForm = CredentialsForm & {
  type: CredentialsType;
};

export type ToggleableServiceAlias =
  | PaymentServiceAlias.MADE_TASK
  | PaymentServiceAlias.SOLAR_STAFF;
